import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// realtime routing
const RealTimeTableData = Loadable(lazy(() => import('./Realtime/table-data')));

// realtime routing
const ScadaScreenTableView = Loadable(lazy(() => import('./ScadaScreens/table-data')));

// Historical routing
const HistoricalDataTableView = Loadable(lazy(() => import('./PolingInterval/table-view/HistoricalDataTableView')));
const HistoricalDataChartView = Loadable(lazy(() => import('./PolingInterval/chart-view/HistoricalDataChartView')));
const HistoricalDataChartViewV2 = Loadable(lazy(() => import('./PolingInterval/chart-view/HistoricalDataChartViewV2')));

// Trigger routing
const TriggerDataTableView = Loadable(lazy(() => import('./Trigger/table-view/TriggerDataTableView')));
const TriggerDataChartView = Loadable(lazy(() => import('./Trigger/chart-view/TriggerDataChartView')));
const TriggerDataChartViewV2 = Loadable(lazy(() => import('./Trigger/chart-view/TriggerDataChartViewV2')));

// Alarm routing
const AlarmHistoryList = Loadable(lazy(() => import('./Alarm/history/alarmHistoryList')));

// Maintenance routing
const Dashboard = Loadable(lazy(() => import('./Maintenance/dashboard/customerDashboard')));
const PartSummaryList = Loadable(lazy(() => import('./Maintenance/parts/partSummaryList')));
// ==============================|| MAIN ROUTING ||============================== //

const DataMonitorRoutes = {
    path: '/datamonitor',
    children: [
        {
            path: 'realtime',
            children: [
                {
                    path: 'table-data',
                    element: <RealTimeTableData />
                }
            ]
        },
        {
            path: 'screens',
            children: [
                {
                    path: 'table-view',
                    element: <ScadaScreenTableView />
                }
            ]
        },
        {
            path: 'historicalData',
            children: [
                {
                    path: 'table-view',
                    element: <HistoricalDataTableView />
                },
                {
                    path: 'chart-view',
                    element: <HistoricalDataChartView />
                },
                {
                    path: 'chart-viewV2',
                    element: <HistoricalDataChartViewV2 />
                }
            ]
        },
        {
            path: 'trigger',
            children: [
                {
                    path: 'table-view',
                    element: <TriggerDataTableView />
                },
                {
                    path: 'chart-view',
                    element: <TriggerDataChartView />
                },
                {
                    path: 'chart-viewV2',
                    element: <TriggerDataChartViewV2 />
                }
            ]
        },
        {
            path: 'alarm',
            children: [
                {
                    path: 'history',
                    element: <AlarmHistoryList />
                }
            ]
        },
        {
            path: 'maintenance',
            children: [
                {
                    path: 'dashboard',
                    element: <Dashboard />
                },
                {
                    path: 'summary',
                    element: <PartSummaryList />
                }
            ]
        }
    ]
};

export default DataMonitorRoutes;
