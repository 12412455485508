import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

const AlarmConfig = Loadable(lazy(() => import('./alarm-config/AlarmConfig')));

// ==============================|| MAIN ROUTING ||============================== //

const AlarmRoutes = {
    path: 'alarm',
    children: [
        {
            path: 'alarm-config',
            element: <AlarmConfig />
        }
    ]
};

export default AlarmRoutes;
