// project imports

// routes
import DataReceiverRoutes from './Connection/data-receiver/DataReceiverRoutes';
import ModbusRoutes from './Connection/modbus/ModbusRoutes';
import CustomerManagementRoutes from './CustomerManagement/CustomerManagementRoutes';
import DefaultRoutes from './General/default/DefaultRoutes';
import DataGatewayRoutes from './General/data-gateway/DataGatewayRoutes';
import PollingIntervalRoutes from './PollingInterval/PollingIntervalRoutes';
import RealtimeRoutes from './Realtime/RealtimeRoutes';
import ScadsScreenRoutes from './ScadaScreens/ScadsScreenRoutes';
import TroggerRoutes from './Trigger/TroggerRoutes';
import AlarmRoutes from './Alarm/AlarmRoutes';
import MaintenanceRoutes from './Maintenance/MaintenanceRoutes';
import UserManagementRoutes from './UserManagement/UserManagementRoutes';
// ==============================|| MAIN ROUTING ||============================== //

const SettingsRoutes = {
    path: 'settings',
    children: [
        ModbusRoutes,
        DataReceiverRoutes,
        RealtimeRoutes,
        ScadsScreenRoutes,
        PollingIntervalRoutes,
        TroggerRoutes,
        UserManagementRoutes,
        CustomerManagementRoutes,
        DefaultRoutes,
        AlarmRoutes,
        MaintenanceRoutes,
        DataGatewayRoutes
    ]
};

export default SettingsRoutes;
